import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';

import styles from '../styles/legal.module.scss';

const LegalNav = () => (
    <nav>
        <ul>
            <li>
                <Button
                    variant='text'
                    component={Link}
                    to='/shipping-and-returns/'
                    fullWidth={true}
                    activeClassName={styles.active}
                    classes={{
                        root: styles.navlink,
                        label: styles.label
                    }}
                >
                    Shipping and Returns
                </Button>
            </li>
            <li>
                <Button
                    variant='text'
                    component={Link}
                    to='/privacy-policy/'
                    fullWidth={true}
                    activeClassName={styles.active}
                    classes={{
                        root: styles.navlink,
                        label: styles.label
                    }}
                >
                    Privacy Policy
                </Button>
            </li>
            <li>
                <Button
                    variant='text'
                    component={Link}
                    to='/terms-and-conditions/'
                    fullWidth={true}
                    activeClassName={styles.active}
                    classes={{
                        root: styles.navlink,
                        label: styles.label
                    }}
                >
                    Terms and Conditions
                </Button>
            </li>
        </ul>
    </nav>
);

export default LegalNav;